<template>
  <CRow>
    <CCol col="12">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalPDF ref="visorPDF"></ModalPDF>
      <Confirm ref="confirm"></Confirm>
      <ModalVideo ref="modalVideo"></ModalVideo>
      <transition name="slide">
        <CCard>
          <CCardHeader>
            <strong>Facturas Clientes Externos </strong>
            <div class=" card-header-actions">
              <form class="form-inline">
                <input type="hidden" value="<?php echo $user->id ?>" id="user"/>
                <b>Filas por p&aacute;gina</b>
                <select class="form-control mr-2 ml-1" @change="sizeChange($event)"
                        v-model="filasPorPagina">
                  <option v-for="option in sizeoptions" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>

                <button type="button" class="btn btn-outline-dark mr-2"
                        @click="borrarFiltroBtnOnClick()">
                  <font-awesome-icon icon="fa-sort-amount-up-alt"/>
                  Quitar Filtro
                </button>

                <button type="button" class="btn btn-outline-primary ml-2"
                        data-toggle="tooltip" data-html="true" @click="create()"
                        title="Nueva factura">
                  <font-awesome-icon icon="fa-plus"/>
                  Nueva factura
                </button>

                <button type="button" class="btn btn-outline-success ml-2"
                        data-toggle="tooltip" data-html="true" @click="excelBtnOnClick()"
                        title="Para exportar todos los datos en un archivo Excel<br/>seleccione la opci&oacute;n <u>Todos</u> en <b>Filas por p&aacute;gina</b>">
                  <font-awesome-icon icon="fa-file-excel"/>
                  Exportar a Excel
                </button>

                <CDropdown
                    inNav
                    class="c-header-nav-items"
                    placement="bottom-end"
                    add-menu-classes="pt-0"
                    :caret="false"
                >
                  <template #toggler>
                    <CHeaderNavLink title="Ver videos de ayuda">
                      <font-awesome-icon icon="fas fa-question-circle" size="2x"/>
                    </CHeaderNavLink>
                  </template>
                  <CDropdownItem >
                    <a href="#" @click="$refs.modalVideo.mostrarVideo('video-factura-externa-nuevo.mp4')"
                       class="text-info" title="Ver video de ayuda">
                      <font-awesome-icon icon="fas fa-video" />
                      Nueva factura (Cliente Externo)
                    </a>
                  </CDropdownItem>
                  <CDropdownItem >
                    <a href="#" @click="$refs.modalVideo.mostrarVideo('video-factura-externa-anular.mp4')"
                       class="text text-danger" title="Ver video de ayuda">
                      <font-awesome-icon icon="fas fa-video" />
                      Anular Factura (Cliente Externo)
                    </a>
                  </CDropdownItem>
                </CDropdown>
              </form>
            </div>
          </CCardHeader>
          <CCardBody class="p-0">
            <JqxGrid :width="'100%'" ref="gridSystem"
                     :source="dataAdapter"
                     :autoheight="true" :autorowheight="false" :rowsheight="50"
                     :pageable="true"
                     :pagesize="6"
                     :virtualmode="true"
                     :rendergridrows="rendergridrows"
                     :columns="columns" :enablebrowserselection="true"
                     :enabletooltips="true" :filterable="true"
                     :showfilterrow="true"
                     :sortable="true"
                     :pagermode="'simple'" :localization="localization"
                     :theme="'bootstrap'" :columnsresize="true"/>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'
import JqxGrid from "jqwidgets-scripts/jqwidgets-vue/vue_jqxgrid";
import jqxInput from "jqwidgets-scripts/jqwidgets-vue/vue_jqxinput";
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import ModalPDF from "@/views/notifications/ModalPDF";
import Confirm from "@/views/notifications/Confirm";
import ModalVideo from "@/views/notifications/ModalVideo";

var sourceLst;
var address;
var modalPdf;
var pagesizeoptions = [6, 10, 50, 100, 500, 1000, 5000, 10000];
var pagesize = pagesizeoptions[0];

export default {
  name: 'Alumnos',
  components: {
    ModalVideo,
    Confirm,
    ModalPDF,
    Alerts,
    Toast,
    JqxGrid,
    jqxInput
  },
  data: () => {
    return {
      darkModal: false,
      items: [],
      currentPage: 1,
      perPage: 7,
      totalRows: 0,
      sizeoptions: [],
      sizeoptionsSelect: '',

      dataAdapter: new jqx.dataAdapter(sourceLst, {
        loadError: function (xhr, status, error) {
          console.log("Error ocurrido al obtener los datos del servidor, " + status + ", " + error);
          location.href = location.protocol + "//" + location.host + "/#/login";
        }
      }),
      rendergridrows: (params) => {
        return params.data;
      },
      columns: [
        {
          text: 'Número Factura', datafield: "factura_numero", width: '5%', cellsalign: 'center',
          cellclassname: function (row, datafield, value, rowdata) {
            return (rowdata.estado === 'A') ? "text-danger" : "";
          },
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Número<br />Factura</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar número de factura"});
          }
        }, {
          text: 'Monto', datafield: "monto_total", width: '7%', cellsalign: 'right', cellsformat: "f2",
          cellclassname: function (row, datafield, value, rowdata) {
            return (rowdata.estado === 'A') ? "text-danger" : "";
          },
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Monto</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar por monto"});
          }
        }, {
          text: 'NIT Factura', datafield: "factura_nit", width: '8%',
          cellclassname: function (row, datafield, value, rowdata) {
            return (rowdata.estado === 'A') ? "text-danger" : "";
          },
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">NIT<br/>Factura</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar por NIT"});
          }
        }, {
          text: 'Factura Nombre', datafield: "factura_nombre", width: '10%',
          cellclassname: function (row, datafield, value, rowdata) {
            return (rowdata.estado === 'A') ? "text-danger" : "";
          },
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Factura<br/>Nombre</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar por nombre"});
          }
        }, {
          text: 'Concepto', datafield: "concepto", width: '10%',
          cellclassname: function (row, datafield, value, rowdata) {
            return (rowdata.estado === 'A') ? "text-danger" : "";
          },
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Concepto</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar por concepto"});
          }
        }, {
          text: 'CI/Pasaporte del Estudiante', datafield: "estudiante_ci", width: '10%',
          cellclassname: function (row, datafield, value, rowdata) {
            return (rowdata.estado === 'A') ? "text-danger" : "";
          },
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">CI/Pasaporte<br/>del Estudiante</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Código Alumno"});
          }
        }, {
          text: 'Nombre del Estudiante', datafield: "estudiante_nombre", width: '15%',
          cellclassname: function (row, datafield, value, rowdata) {
            return (rowdata.estado === 'A') ? "text-danger" : "";
          },
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Nombre del<br/>Estudiante</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar por observacion"});
          }
        }, {
          text: 'cajero', datafield: "cajero", width: '7%',
          cellclassname: function (row, datafield, value, rowdata) {
            return (rowdata.estado === 'A') ? "text-danger" : "";
          },
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">Cajero</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar por cajero "});
          }
        }, {
          text: 'fecha_venta', datafield: "fecha_venta", width: '12%',
          cellclassname: function (row, datafield, value, rowdata) {
            return (rowdata.estado === 'A') ? "text-danger" : "";
          },
          cellsformat: 'yyyy-MM-dd HH:mm:ss', filtertype: 'range',
          renderer: function () {
            return '<div style="margin-top: 5px; margin-left: 5px;">fecha_venta</div>';
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar por fecha venta "});
          }
        },
        {
          text: 'Estado', datafield: "estado", width: '4%',
          cellclassname: function (row, datafield, value, rowdata) {
            return (rowdata.estado === 'A') ? "text-danger" : "";
          },
          createfilterwidget: function (column, columnElement, widget) {
            widget.jqxInput({width: '100%', height: 27, placeHolder: "Buscar Estado"});
          }
        },
        {
          text: "", width: '6%', columntype: "button",
          cellclassname: "btn-green",
          renderer: function () {
            return '';
          },
          cellsrenderer: function () {
            return "Imprimir"
          },
          buttonclick: function (row, event) {
            let button = $(event.currentTarget);
            let grid = button.parents("[id^=jqxGrid]");
            let rowData = grid.jqxGrid('getrowdata', row);
            modalPdf.$refs.alert.show('Descargando documento');
            axios.get(
                address + '/api/factura/' + rowData.id + '/facturapdf?token=' + localStorage.getItem("api_token"),
                {responseType: 'blob'}
            )
                .then((response) => {
                  modalPdf.$refs.alert.hide();
                  modalPdf.$refs.visorPDF.mostrarDocumento(response.data);
                })
                .catch(function (error) {
                  modalPdf.$refs.alert.hide();
                  modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
                });
          }
        },
        {
          text: "", width: '6%', columntype: "button",
          cellclassname: function (row, datafield, value, rowdata) {
            return (rowdata.estado === 'A') ? "text-danger" : "btn-red";
          },
          renderer: function () {
            return '';
          },
          cellsrenderer: function (index, datafield, value, defaultvalue, column, rowdata) {
            return (rowdata.estado === 'A') ? "Anulado" : "Anular";
          },
          buttonclick: function (row, event) {
            let button = $(event.currentTarget);
            let grid = button.parents("[id^=jqxGrid]");
            let rowData = grid.jqxGrid('getrowdata', row);
            if (rowData.estado !== 'A') {
              let fnAnular = function () {
                axios.get(
                    address + '/api/facturaexterna/' + rowData.id + '/destroy?token=' + localStorage.getItem("api_token")
                ).then((response) => {
                  grid.jqxGrid('updatebounddata');
                  if (response.data.success === 'OK') {

                  }
                });
              };
              modalPdf.$refs.confirm.confirm('Esta sugur@ de anular esta factura?', '', fnAnular)
            } else {
              modalPdf.$refs.mensajeToast.makeToast('', 'No se puede anular 2 veces esta factura', 'danger');
            }
          }
        },
      ],
      localization: {
        'emptydatastring': "No existen datos para esta SEDE",
      },
    }
  },
  beforeCreate: function () {
    const url = this.$apiAdress + '/api/facturaexterna/E?token=' + localStorage.getItem("api_token") + '&sede_id=' + localStorage.sedeSeleccionada;
    address = this.$apiAdress;
    sourceLst = {
      url: url,
      datafields: [
        {name: 'id', type: "int"},
        {name: 'factura_numero', type: "int"},
        {name: 'factura_nit', type: "string"},
        {name: 'factura_nombre', type: "string"},
        {name: 'estudiante_ci', type: "string"},
        {name: 'monto_total', type: "float"},
        {name: 'concepto', type: "string"},
        {name: 'estudiante_nombre', type: "string"},
        {name: 'fecha_venta', type: "date", format: 'yyyy-MM-dd H:mm:ss'},
        {name: 'cajero', type: "string"},
        {name: 'carrera', type: "string"},
        {name: 'estado', type: "string"},
      ],
      datatype: "json",
      root: "Rows",
      id: 'id',
      sortcolumn: 'factura_numero',
      sortdirection: 'desc',
      cache: false,
      beforeprocessing: (data) => {
        if (data != null) {
          sourceLst.totalrecords = data[0].TotalRows;
          let i = 0;
          this.sizeoptions = [];
          while (i < 8 && pagesizeoptions[i] < data[0].TotalRows) {
            this.sizeoptions[i] = {text: pagesizeoptions[i], value: pagesizeoptions[i]};
            if (i === 0) {
              this.filasPorPagina = pagesizeoptions[i];
            }
            i++;
          }
          this.sizeoptions[i] = {text: "TODOS", value: data[0].TotalRows};
          if (i === 0) {
            this.filasPorPagina = data[0].TotalRows;
          }
        }
        modalPdf.$refs.alert.hide();
      },
      filter: () => {
        this.$refs.gridSystem.updatebounddata("filter");
      },
      sort: () => {
        this.$refs.gridSystem.updatebounddata("sort");
      },
    };
  },
  methods: {
    excelBtnOnClick: function () {
      this.$refs.gridSystem.hidecolumn('documentos');
      this.$refs.gridSystem.hidecolumn('editar');
      this.$refs.gridSystem.hidecolumn('eliminar');
      let date = new Date();
      let dateStr =
          date.getFullYear() + "" +
          ("00" + (date.getMonth() + 1)).slice(-2) + "" +
          ("00" + date.getDate()).slice(-2) + "_" +
          ("00" + date.getHours()).slice(-2) + "" +
          ("00" + date.getMinutes()).slice(-2) + "" +
          ("00" + date.getSeconds()).slice(-2);
      this.$refs.gridSystem.exportdata('xls', 'Inscritos' + dateStr, true, null, true, modalPdf.$apiAdress + "/api/reporte/exportarxls/" + '?token=' + localStorage.getItem("api_token"));
      this.$refs.gridSystem.showcolumn('documentos');
      this.$refs.gridSystem.showcolumn('editar');
      this.$refs.gridSystem.showcolumn('eliminar');
    },
    create: function () {
      this.$router.push({path: '/facturaexterna/create'});
    },
    borrarFiltroBtnOnClick: function () {
      this.$refs.gridSystem.clearfilters();
    },
    filasPorPagina: function (e) {
      try {
        pagesize = parseInt($(this).val());
        if (pagesize > 10) {
          this.$router.gridSystem.jqxGrid({
            height: (50 * pagesize) + 118,
            rowsHeight: 50,
            autoheight: false,
            autorowheight: false,
          });
        }
        if (pagesize <= 10) {
          gridSystem.jqxGrid({
            autoheight: true,
            autorowheight: true,
          });
        }
      } catch (e) {

      }
    }
  },
  mounted: function () {
    modalPdf = this;
  }
}
</script>